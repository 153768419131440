<template>
  <div class="page project" :style="{ 'margin-top': `${headerHeight}px` }">
    <SectionSelector
      v-for="(section, key) in sections"
      ref="sections"
      :enabled="section.enabled"
      :hash="section.hashAnchor"
      :variant="section.variant"
      :data="section.data"
      :extra="section.extra"
      :multi-project="section.multi_project"
      :section="section.type"
      :order-no="section.order_no"
      :key="key"
      v-scroll="handleScroll"
    />
  </div>
</template>

<script>
import SectionSelector from "./SectionSelector";
import buildingStore from "../../components/organisms/project/building/store";
import config from "../../config";

export default {
  name: "PageProject",
  metaInfo() {
    return {
      title: this.meta.baseTitle,
      titleTemplate: `${this.activeProjectObject.name} - ${this.meta.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.meta.description,
        },
      ],
    };
  },
  components: {
    SectionSelector,
  },
  computed: {
    buildingStore() {
      return buildingStore;
    },
    meta() {
      const tempMeta = this.$route.meta.page.meta;
      const projectSpecificMeta = tempMeta.projectSpecificMeta
        ? tempMeta.projectSpecificMeta[this.activeProject] || {}
        : {};
      const meta = {
        ...tempMeta,
        ...projectSpecificMeta,
      };
      return meta;
    },
    sectionsData() {
      return this.$route.meta.page.sections;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    projects() {
      return this.$store.state?.project?.projects || [];
    },
    activeProjectObject() {
      return this.projects?.find((p) => p.slug === this.activeProject) || null;
    },
    spaces() {
      return this.$store.state.project.project.spaces;
    },
    baseConstants() {
      return this.$store.getters.constants.base;
    },
    buildingRef() {
      const found = this.$refs.sections.find((s) => s.$refs.buildingSection);
      if (!found) return null;
      return found.$refs.buildingSection.$refs.building;
    },
  },
  data() {
    return {
      sections: [],
      headerHeight: 0,
    };
  },
  created() {
    this.sortSections();
    const projectConstants = this.$store.getters.constants.project;
    this.$store.dispatch(
      projectConstants.withNamespace(
        projectConstants.action.LOAD_LANDLORD_DETAILS
      )
    );
  },
  watch: {
    activeProject: function (value, old) {
      if (value !== old) {
        this.buildingRef.initializeBuilding3D();
      }
    },
    $route: {
      deep: true,
      handler: function (to, from) {
        this.sortSections();
        if (this.$store.state.base.documentIsReady) {
          const base = this.$store.getters.constants.base;
          this.$store.dispatch(
            base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
            false
          );
        }
        if (to.hash) {
          document.querySelector(to.hash).scrollIntoView({
            behavior: "smooth",
          });
        }
      },
    },
  },
  mounted() {
    window.addEventListener("keydown", this.disableArrowScroll, false);
    this.$store.state.project.activeProject &&
      this.buildingRef.initializeBuilding3D();
    const requestOffer = this.$store.getters.constants.requestOffer;
    this.$store.dispatch(
      requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
      null
    );
    this.headerHeight = document.getElementById("header-project")
      ? document.getElementById("header-project").clientHeight
      : 0;
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.disableArrowScroll);
  },
  methods: {
    resizeListener(e) {
      this.headerHeight = document.getElementById("header-project")
        ? document.getElementById("header-project").clientHeight
        : 0;
    },
    sortSections() {
      this.sections = [...this.sectionsData].sort((a, b) => {
        if (a.order_no < b.order_no) {
          return -1;
        }
        if (a.order_no > b.order_no) {
          return 1;
        }
        return 0;
      });
    },
    disableArrowScroll(e) {
      if (
        ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1
      ) {
        e.preventDefault();
      }
    },
    handleScroll(event, element) {
      const scrollY = window.scrollY + 100;
      document
        .querySelectorAll('a.navigation-link[href*="#"]')
        .forEach((link) => {
          link.classList.remove("active");
          // const section = document.getElementById(`${link.getAttribute('href').substring(1)}`).parentElement;
          const section = document.getElementById(
            `${link.href.split("#")[1]}`
          ).parentElement;
          if (
            section.offsetTop <= scrollY &&
            section.offsetTop + section.offsetHeight > scrollY
          ) {
            link.classList.add("active");
          }
        });
    },
    removePageLoader() {
      this.$store.dispatch(
        this.baseConstants.withNamespace(
          this.baseConstants.action.CHANGE_PAGE_LOADING_STATE
        ),
        false
      );
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/buttons";

.project {
  .section {
    position: relative;
    max-width: 80.334rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 auto;
    @media only screen and (max-width: 1000px) {
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }

    &.section-building,
    &.section-contact,
    &.section-few-facts {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    &.section-few-facts {
      .atom-title,
      .atom-facts-parent {
        width: 100%;
        max-width: 80.334rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: 0 auto;
        @media only screen and (max-width: 1000px) {
          max-width: 100%;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
      .atom-title {
        margin: 0 auto 2.5rem;
        @media only screen and (max-width: 1000px) {
          margin: 0 0 24px;
        }
      }
    }

    &.section-building {
      .navigation-target {
        top: -5rem;
        @media only screen and (max-width: 767px) {
          top: -80px;
        }
      }
    }

    .navigation-target {
      position: absolute;
      top: -4.5rem;
      left: 0;
      @media only screen and (max-width: 767px) {
        top: -80px;
      }
    }

    .top-title {
      display: flex;
      flex-direction: column;
      flex: 100%;
    }
  }
}
</style>
